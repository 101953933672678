$(document).ready(function () {
  var currentPage = 1; // Initialize currentPage

  // Open/close custom dropdown
  $(".selected").on("click", function () {
    $("#tag-options").toggle();
    $(this).find("span").toggleClass("active"); // Correctly select the span within the clicked element
  });

  // Open/close custom dropdown
  $(".service-selected").on("click", function () {
    $("#service-options").toggle();
    $(this).find("span").toggleClass("active"); // Correctly select the span within the clicked element
  });

  // Store the currently selected tag and service
  let currentTagId = null;
  let currentServiceName = null;

  // Handle tag dropdown option click
  $(".tag-dropdown .dropdown-option").on("click", function () {
    currentTagId = $(this).data("value");
    const tagName = $(this).text();
    $("#selected-tag").text(tagName);
    $(".tag-dropdown .dropdown-option").removeClass("selected");
    $(this).addClass("selected"); // Add the selected class to the clicked option
    $("#tag-options").hide();

    // Trigger AJAX request
    filterPosts();
  });

  // Handle service dropdown option click (only for pages that use services)
  $(".service-dropdown .dropdown-option").on("click", function () {
    currentServiceName = $(this).text(); // Use the service name directly
    $("#selected-service").text(currentServiceName);
    $(".service-dropdown .dropdown-option").removeClass("selected");
    $(this).addClass("selected"); // Add the selected class to the clicked option
    $("#service-options").hide();

    // Trigger AJAX request
    filterPosts();
  });

  // Function to trigger AJAX request based on selected filters
  function filterPosts() {
    const catName = $("#selected-service").data("blog-value"); // Assuming you set the category value here

    // Prepare data for the AJAX request
    const requestData = {
      action: "filter_posts_by_tag",
      tag_id: currentTagId,
      cat_name: catName,
    };

    // Include service name only if it has been selected
    if (currentServiceName) {
      requestData.service_name = currentServiceName; // Add service name to the request
    }

    // AJAX request to filter posts
    $.ajax({
      url: myAjax.ajaxurl,
      type: "POST",
      data: requestData,
      success: function (response) {
        $(".all-posts").html(response); // Update posts
        currentPage = 1; // Reset page number
        $(".load-more-button").show();
      },
      error: function () {
        console.log("An error occurred while processing the AJAX request.");
      },
    });
  }

  // Handle "Load More" functionality
  $(".load-more-button").on("click", function (e) {
    e.preventDefault();
    currentPage++; // Increment the page number

    console.log($(".tags").data("value"));

    var tagId = $(".dropdown-option.selected").data("value"); // Get selected tag
    var catName = $(".dropdown-option.selected").data("blog-value"); // Get selected category
    console.log(catName);

    // If no category is selected, fallback to the category of the displayed posts
    if (!catName) {
      console.log("No catname found");
      var firstPostCat = $(".tags").first().data("value");
      catName = firstPostCat || ""; // Fallback to the first post's category
      console.log(firstPostCat);
    }

    // AJAX request to load more posts
    $.ajax({
      url: myAjax.ajaxurl,
      type: "POST",
      data: {
        action: "load_more_posts",
        tag_id: tagId, // Pass selected tag (could be undefined)
        cat_name: catName, // Pass the category (either selected or fallback)
        paged: currentPage,
      },
      success: function (response) {
        if (response.success && response.data) {
          $(".all-posts").append(response.data); // Append new posts

          // Hide "Load More" button if there are no more posts to load
          if (response.data.trim() === "") {
            $(".load-more-button").hide(); // Hide button if no more posts
          }
        } else {
          $(".load-more-button").hide(); // Hide button if no more posts
          $(".all-posts").append("<p>No more posts to load.</p>");
        }
      },
      error: function () {
        console.log("An error occurred while processing the AJAX request.");
      },
    });
  });

  // Hide dropdown if clicked outside
  $(document).on("click", function (e) {
    if (!$(e.target).closest(".custom-dropdown").length) {
      $("#tag-options").hide();
    }
  });

  // Handle the "Clear Filters" button click
  $(".clear-filters").on("click", function () {
    // Reset the selected tag display
    if ($("#selected-tag").hasClass("sector")) {
      $("#selected-tag").html(
        '<p>Filter by sector:</p><span class="icon"></span>'
      ); // Update to default text with HTML
    } else {
      $("#selected-tag").html("<p>Filter by:</p>"); // Update to default text with HTML
    }

    $("#selected-service").html(
      '<p>Filter by service:</p><span class="icon"></span>'
    ); // Update to default text with HTML

    // Reset the selected option styles (remove selected class)
    $(".dropdown-option").removeClass("selected");

    // AJAX request to fetch posts from the current category without tag filtering
    $.ajax({
      url: myAjax.ajaxurl,
      type: "POST",
      data: {
        action: "filter_posts_by_tag", // Same action for filtering
        tag_id: "", // Empty tag ID to remove tag filter
        cat_name: $("#tag-options .dropdown-option").data("blog-value"), // Pass the current category
      },
      success: function (response) {
        $(".all-posts").html(response); // Update posts container
        currentPage = 1; // Reset pagination if necessary
        $(".load-more-button").show();
      },
      error: function () {
        console.log("An error occurred while clearing the filters.");
      },
    });
  });
});
