$(document).ready(function () {
  function animateNumbers() {
    $(".key-stat").each(function () {
      var $this = $(this);
      var textValue = $this.text(); // Get the text (this might include "%")
      var numberValue = parseInt(textValue, 10); // Extract the numeric value

      // Reset text if it has already been animated
      if ($this.data("animated")) return;

      $this.data("animated", true); // Mark as animated

      if (!isNaN(numberValue)) {
        // First reset the text to 0 (or "0%")
        $this.text(0 + (textValue.includes("%") ? "%" : ""));

        // Animate from 0 to the numeric value
        $({ counter: 0 }).animate(
          { counter: numberValue },
          {
            duration: 2000, // Duration of the animation in milliseconds
            easing: "swing", // Easing function
            step: function () {
              // Update the text with the current counter value
              $this.text(
                Math.floor(this.counter) + (textValue.includes("%") ? "%" : "")
              );
            },
            complete: function () {
              // Ensure it ends with the final value
              $this.text(numberValue + (textValue.includes("%") ? "%" : ""));
            },
          }
        );
      }
    });
  }

  $(window).on("scroll", function () {
    var statsOffset = $(".stats").offset().top;
    var statsHeight = $(".stats").outerHeight();
    var windowScroll = $(window).scrollTop();
    var windowHeight = $(window).height();

    // Check if the container is in view (roughly 100px in view)
    if (
      statsOffset < windowScroll + windowHeight - 100 &&
      statsOffset + statsHeight > windowScroll
    ) {
      animateNumbers();
    } else {
      // Reset the animated flag if out of view to allow re-animation
      $(".key-stat").data("animated", false);
    }
  });
});
