$(document).ready(function () {
  // Set the first accordion item as active on document ready
  var firstAccordionItem = $(".accordion-item").first();
  var firstIndex = firstAccordionItem.data("index");
  var currentIndex = 0;
  var totalItems = $(".accordion-item").length;
  var autoScrollInterval;

  if (firstAccordionItem.length) {
    // Activate the first item
    firstAccordionItem.addClass("active");
    // Show the first image
    $('.accordion-image[data-index="' + firstIndex + '"]').show();

    // Delay completion for the first item to prevent backwards animation
    setTimeout(function () {
      firstAccordionItem.addClass("completed");
    }, 4000); // Match the duration of the animation
  }

  // Function to activate the accordion item and trigger animations
  function activateAccordionItem(index) {
    // Hide all images
    $(".accordion-image").hide();

    // Show the image corresponding to the accordion item
    $('.accordion-image[data-index="' + index + '"]').show();

    // Remove the active and completed classes from all items
    $(".accordion-item").removeClass("active completed");

    // Activate the current accordion item
    var activeItem = $('.accordion-item[data-index="' + index + '"]');
    activeItem.addClass("active");

    // After 4 seconds, mark the item as completed without removing 'active'
    setTimeout(function () {
      activeItem.addClass("completed"); // Just add completed, don't remove active
    }, 4000); // Match the duration of the animation
  }

  // Auto-scroll function to advance to the next item
  function autoScroll() {
    currentIndex = (currentIndex + 1) % totalItems; // Increment and wrap around
    activateAccordionItem(currentIndex);
  }

  // Start auto-scroll with a 4-second interval
  autoScrollInterval = setInterval(autoScroll, 4000);

  // Handle manual click events and stop auto-scroll
  $(".accordion-item a").on("click", function (e) {
    e.preventDefault(); // Prevent default link behavior

    // Get the index of the clicked item
    var index = $(this).closest(".accordion-item").data("index");
    currentIndex = index; // Update current index to clicked item

    // Stop the auto-scroll permanently after a click
    clearInterval(autoScrollInterval);

    // Add 'completed' class to the previously active item and remove 'active' class
    $(".accordion-item.active").removeClass("active").addClass("completed");

    // Activate the clicked item
    $(this)
      .closest(".accordion-item")
      .addClass("active")
      .removeClass("completed");

    // Manually show the corresponding image
    $(".accordion-image").hide();
    $('.accordion-image[data-index="' + index + '"]').show();
  });

  // Toggle behavior for manual click without collapsing others
  $(".accordion-item").on("click", function () {
    clearInterval(autoScrollInterval); // Stop auto-scroll on interaction
    $(".accordion-item").removeClass("active"); // Remove active class from all
    $(this).addClass("active"); // Add active class to the clicked item
  });

  // Standard accordion styles
  var firstAccordionText = $(".single-accordion").first();
  firstAccordionText.addClass("active");

  $(".single-accordion").on("click", function (e) {
    e.preventDefault(); // Prevent the default link behavior

    if ($(this).hasClass("active")) {
      $(this).removeClass("active");
    } else {
      $(".single-accordion").removeClass("active");
      $(this).addClass("active");
    }
  });
});
