$(document).ready(function () {
  if ($("body").hasClass("page-template-content-cyber-emergency")) {
    // Find all elements with the color #0049ff and change it to your choice (e.g., #FF7F5C)
    $("*")
      .not(".site-footer, .mega-menu-container, #circle, .menu .blue-tag")
      .each(function () {
        var currentColor = $(this).css("color");
        var currentBackgroundColor = $(this).css("background-color");

        // Convert color to RGB to match jQuery output (because jQuery outputs color in RGB)
        if (currentColor === "rgb(0, 73, 255)") {
          $(this).css("color", "#FF7F5C");
        }

        if (currentBackgroundColor === "rgb(0, 73, 255)") {
          $(this).css("background-color", "#FF7F5C");
        }
      });
  }

  // Function to apply custom styles inside TinyMCE editors
  function applyStylesToTinyMCE() {
    // Loop through all iframes that belong to TinyMCE editors
    $("iframe").each(function () {
      var iframe = $(this)[0]; // Get the iframe DOM element

      // Ensure the iframe has loaded its content
      var iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

      if (iframeDoc && iframeDoc.body) {
        // Append your custom CSS style inside the iframe's body
        var customStyles =
          "<style>" +
          "body { background-color: #f0f0f0; }" + // Example style
          "p { color: #333; font-family: Arial, sans-serif; }" + // Example text style
          "</style>";

        $(iframeDoc.head).append(customStyles); // Append styles to the iframe's head
      }
    });
  }

  // Run the function when the page loads and after TinyMCE is initialized
  $(window).on("load", applyStylesToTinyMCE);

  // Optional: If you need to reapply styles when TinyMCE is dynamically loaded or changed
  $(document).on("tinymce-editor-init", applyStylesToTinyMCE);
});
