$(document).ready(function () {
  $(".mobile-burger").on("click", function (e) {
    e.preventDefault(); // Prevent the default link behavior
    $(this).toggleClass("active");
    $(".mobile-menu-container").toggleClass("active");
  });

  $(".first-level").on("click", function (e) {
    e.preventDefault(); // Prevent the default link behavior

    $(".menu-item > a").on("click", function (e) {
      var link = $(this).attr("href");
      console.log(link);
      // Let regular links work as usual (no preventDefault)
      if (link === "#" || link === "") {
        e.preventDefault(e);
      } else {
        window.location.href = link;
      }
    });

    if ($(this).hasClass("active")) {
      $(this).removeClass("active");
      $(this).find(".mobile-submenu-container").removeClass("active");
    } else {
      $(".first-level").removeClass("active");
      $(".mobile-submenu-container").removeClass("active");

      $(this).toggleClass("active");
      $(this).find(".mobile-submenu-container").toggleClass("active");
    }
  });
});
