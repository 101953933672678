$(document).ready(function () {
  // Select the parent menu items
  var $parentMenuItems = $("#primary-menu > li.menu-item-has-children");

  // Select the target element where the mega menus will be moved
  var $subMenu = $("#sub-menu");

  // Loop through each parent menu item and assign unique IDs
  $parentMenuItems.each(function (index) {
    // Create a unique ID based on the index for both the parent menu and the mega menu
    var uniqueId = "menu-" + index;

    // Assign the unique ID to the parent menu item
    $(this).attr("data-menu", uniqueId);

    // Find the corresponding mega menu and assign the same ID
    var $megaMenu = $(this).find(".mega-menu-container").attr("id", uniqueId);

    // Move the mega menu to the new location outside the parent menu
    $subMenu.append($megaMenu);
  });

  var hideMegaMenuTimeout;

  // Hover on the parent menu item with children
  $parentMenuItems.hover(
    function () {
      // Cancel any hide timeout when hovering back to the parent
      clearTimeout(hideMegaMenuTimeout);

      // Get the data-menu value to identify the correct mega menu
      var menuId = $(this).data("menu");

      // Immediately hide any other visible mega menu before showing the new one
      $(".mega-menu-container:visible")
        .not("#" + menuId)
        .stop(true, true)
        .fadeOut(50); // Increased fade-out duration

      // Show the correct mega menu instantly
      $("#" + menuId)
        .stop(true, true)
        .fadeIn(300)
        .css("display", "block"); // Increased fade-in duration
    },
    function () {
      // Get the data-menu value to identify the correct mega menu
      var menuId = $(this).data("menu");

      // Delay hiding the mega menu to allow time to hover into it
      hideMegaMenuTimeout = setTimeout(function () {
        $("#" + menuId)
          .stop(true, true)
          .fadeOut(500); // Increased fade-out duration
      }, 100); // 0.5 second delay (500ms)
    }
  );

  // Hover on the mega menu to keep it visible
  $(".mega-menu-container").hover(
    function () {
      // Cancel hide timeout if you're inside the mega menu
      clearTimeout(hideMegaMenuTimeout);

      // Keep the mega menu displayed
      $(this).stop(true, true).fadeIn(300).css("display", "block"); // Increased fade-in duration
    },
    function () {
      // Delay hiding the mega menu when you leave it
      var $megaMenu = $(this);
      hideMegaMenuTimeout = setTimeout(function () {
        $megaMenu.stop(true, true).fadeOut(300); // Increased fade-out duration
      }, 100); // 0.1 second delay
    }
  );
});
