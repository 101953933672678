import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

// Select all blocks with the class 'block-scroll'
let panels = gsap.utils.toArray(".block-scroll");

// Check if the viewport is mobile
const isMobile = window.matchMedia("(max-width: 768px)").matches;

// Create ScrollTrigger for each panel if not on mobile
if (!isMobile) {
  panels.forEach((panel, i) => {
    ScrollTrigger.create({
      trigger: panel,
      start: () =>
        panel.offsetHeight < window.innerHeight ? "top top" : "bottom bottom",
      pin: true,
      pinSpacing: false,
      onEnter: () => {
        $(panel).find(".inner").css("overflow", "auto");
      },
      onLeave: () => {
        $(panel).find(".inner").css("overflow", "hidden");
      },
    });
  });
}

// If needed, add additional code for mobile behavior here
if (isMobile) {
  panels.forEach((panel) => {
    // Add mobile-specific handling here if needed
    $(panel).find(".inner").css("overflow", "hidden");
  });
}
